import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "aptCode", "locCategory", "scenario"];

  declare checkboxTargets: HTMLInputElement[];
  declare aptCodeTarget: HTMLSelectElement;
  declare locCategoryTarget: HTMLSelectElement;
  declare scenarioTarget: HTMLSelectElement;

  declare hasAptCodeTarget: boolean;
  declare hasLocCategoryTarget: boolean;
  declare hasScenarioTarget: boolean;

  connect() {
    console.log("Irreg eurowings connected");
    this.clearLocalStorage();
    this.loadCheckboxState(); // Load checkbox state from localStorage

    this.updateCheckAll(); // Initialize the state of the header checkbox
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.addEventListener("change", this.toggle.bind(this));
    });
    if (this.hasAptCodeTarget) {
      this.aptCodeTarget.addEventListener(
        "change",
        this.filter_change.bind(this)
      );
    } else {
      console.error("aptCode target not found");
    }

    if (this.hasLocCategoryTarget) {
      this.locCategoryTarget.addEventListener(
        "change",
        this.filter_change.bind(this)
      );
    } else {
      console.error("locCategory target not found");
    }

    if (this.hasScenarioTarget) {
      this.scenarioTarget.addEventListener(
        "change",
        this.filter_change.bind(this)
      );
    } else {
      console.error("scenario target not found");
    }

    const checkAllCheckbox = this.element.querySelector(
      'input[name$="[check_all]"]'
    ) as HTMLInputElement;
    if (checkAllCheckbox) {
      checkAllCheckbox.addEventListener("change", this.change.bind(this));
    }
  }

  change(event) {
    const checked = event.target.checked;
    const visibleCheckboxes = this.visibleCheckboxes();

    visibleCheckboxes.forEach((checkbox) => {
      checkbox.checked = checked;
    });

    this.updateCheckAll();
    this.saveCheckboxState();
  }

  toggle() {
    this.updateCheckAll();
    this.saveCheckboxState();
  }

  updateCheckAll() {
    const totalSelectedCheckboxes = this.checkboxTargets.filter(
      (checkbox) => checkbox.checked
    ).length;

    const visibleCheckboxes = this.visibleCheckboxes();
    const allChecked = visibleCheckboxes.every((checkbox) => checkbox.checked);
    const checkAllCheckbox = this.element.querySelector(
      'input[name$="[check_all]"]'
    ) as HTMLInputElement;

    if (checkAllCheckbox) {
      checkAllCheckbox.checked = allChecked;
    }

    const assignScenarioButton = document.getElementById(
      "assign-scenario"
    ) as HTMLButtonElement | null;

    if (assignScenarioButton) {
      if (totalSelectedCheckboxes > 0) {
        assignScenarioButton.disabled = false;
        assignScenarioButton.textContent = `Szenario zu ${totalSelectedCheckboxes} Stele(n) hinzufügen`;
      } else {
        assignScenarioButton.disabled = true;
        assignScenarioButton.textContent = "Assign Szenario";
      }
    }

    const resetToStandardButton = document.getElementById(
      "reset-standard"
    ) as HTMLButtonElement | null;

    if (resetToStandardButton) {
      if (totalSelectedCheckboxes > 0) {
        resetToStandardButton.disabled = false;
        resetToStandardButton.textContent = `Szenario für ${totalSelectedCheckboxes} Stele(n) auf Standard zurücksetzen`;
      } else {
        resetToStandardButton.disabled = true;
        resetToStandardButton.textContent = "Auf Standard zurücksetzen";
      }
    }

    const playerSpotListButton = document.getElementById(
      "player-spots"
    ) as HTMLButtonElement | null;

    if (playerSpotListButton) {
      if (totalSelectedCheckboxes > 0) {
        playerSpotListButton.disabled = false;
        playerSpotListButton.textContent = `Spotliste für ${totalSelectedCheckboxes} Stele(n) anzeigen`;
      } else {
        playerSpotListButton.disabled = true;
        playerSpotListButton.textContent = "Spotliste anzeigen";
      }
    }
  }

  // Get the checkboxes visible after filtering
  visibleCheckboxes() {
    return this.checkboxTargets.filter(
      (checkbox) => !checkbox.closest("tr")?.classList.contains("hidden")
    );
  }

  filter_change() {
    var selectedAptCode = "";
    var selectedLocCategory = "";
    var selectedScenario = "";

    if (this.hasAptCodeTarget) {
      selectedAptCode = this.aptCodeTarget?.value || "";
    } else {
      selectedAptCode;
    }

    if (this.hasLocCategoryTarget) {
      selectedLocCategory = this.locCategoryTarget?.value || "";
    } else {
      selectedLocCategory;
    }

    if (this.hasScenarioTarget) {
      selectedScenario = this.scenarioTarget?.value || "";
    } else {
      selectedScenario;
    }

    this.updateRowsWithFilters(
      selectedAptCode,
      selectedLocCategory,
      selectedScenario
    );

    this.updateCheckAll();
  }

  updateRowsWithFilters(aptCode, locCategory, scenario) {
    const rows = this.element.querySelectorAll("tbody tr");

    rows.forEach((row) => {
      const rowAptCode = row.getAttribute("data-apt-code");
      const categoryCell = row.querySelector("td.loc_category") as HTMLElement;
      const categories = categoryCell
        ? categoryCell.innerText.split("\n").map((cat) => cat.trim())
        : [];
      const rowScenario = row.getAttribute("data-scenario");

      const aptCodeMatches =
        !aptCode || aptCode === "" || rowAptCode === aptCode;
      const locCategoryMatches =
        !locCategory ||
        locCategory === "" ||
        categories.some((cat) => cat === locCategory);
      const scenarioMatches =
        !scenario || scenario === "" || rowScenario === scenario;

      if (aptCodeMatches && locCategoryMatches && scenarioMatches) {
        row.classList.remove("hidden");
      } else {
        row.classList.add("hidden");
      }
    });

    this.restoreCheckboxState();
  }

  clearLocalStorage() {
    localStorage.removeItem("selectedCheckboxes");
  }

  saveCheckboxState() {
    const selectedCheckboxes = this.checkboxTargets
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value);

    localStorage.setItem(
      "selectedCheckboxes",
      JSON.stringify(selectedCheckboxes)
    );
  }

  loadCheckboxState() {
    const selectedCheckboxes = JSON.parse(
      localStorage.getItem("selectedCheckboxes") || "[]"
    );

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = selectedCheckboxes.includes(checkbox.value);
    });
  }

  restoreCheckboxState() {
    const selectedCheckboxes = JSON.parse(
      localStorage.getItem("selectedCheckboxes") || "[]"
    );

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = selectedCheckboxes.includes(checkbox.value);
    });

    this.updateCheckAll();
  }

  show_modal(e) {
    e.preventDefault();

    const selectedCheckboxes = this.checkboxTargets.filter(
      (checkbox) => checkbox.checked
    );

    const selectedBoxIds = selectedCheckboxes
      .map((checkbox) => checkbox.closest("tr")?.getAttribute("data-box-id"))
      .join(",");

    const scenarioModalElement = document.getElementById(
      "selected-box-ids"
    ) as HTMLSpanElement;

    const standardModalElement = document.getElementById(
      "selected-stelen"
    ) as HTMLSpanElement;

    if (scenarioModalElement) {
      scenarioModalElement.textContent = selectedBoxIds;
    }

    if (standardModalElement) {
      standardModalElement.textContent = selectedBoxIds;
    }
  }

  save_modal() {
    const selectedPlayerIds = Array.from(this.checkboxTargets)
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value)
      .join(",");

    const hidden_player_id = document.getElementById(
      "selected-player-ids"
    ) as HTMLInputElement;

    const hidden_standard_player_id = document.getElementById(
      "standard_player_ids"
    ) as HTMLInputElement;

    if (hidden_player_id) {
      hidden_player_id.value = selectedPlayerIds;
    }

    if (hidden_standard_player_id) {
      hidden_standard_player_id.value = selectedPlayerIds;
    }
  }

  change_scenario(event) {
    const selectedScenarioId = event.target.value;

    const descriptionElement = document.querySelector(
      "#scenario-description"
    ) as HTMLElement;

    if (!descriptionElement) {
      console.error("Scenario description element not found");
      return;
    }

    if (selectedScenarioId) {
      const scenarioElement = document.querySelector(
        `#scenario-${selectedScenarioId}`
      ) as HTMLElement;

      if (scenarioElement) {
        const description = scenarioElement.dataset.description || "";
        descriptionElement.innerHTML = description;
      }
    } else {
      descriptionElement.innerHTML =
        "Bitte wählen Sie ein Szenario aus, um die Beschreibung anzuzeigen.";
    }
  }

  show_player_spots(e) {
    e.preventDefault();

    const url = e.target.dataset.url;

    const form = document.querySelector("#edit_scenario_1");

    if (form) {
      window.location.href = `${url}`;
    }
  }
}
