$(document).on("input", "#description-search, #box_id-search", function () {
  console.log("search event fired");

  var descriptionNeedle = RegExp(
    ".*" + $("#description-search").val() + ".*",
    "i"
  );
  var boxIdNeedle = RegExp(".*" + $("#box_id-search").val() + ".*", "i");

  $("#heavy_irregs")
    .children()
    .show()
    .filter(function () {
      var descriptionHaystack = $(this).data("name");
      var boxIdHaystack = $(this).data("box-id");

      var descriptionMatches =
        descriptionHaystack !== undefined
          ? descriptionHaystack.search(descriptionNeedle) !== -1
          : false;
      var boxIdMatches =
        boxIdHaystack !== undefined
          ? boxIdHaystack.search(boxIdNeedle) !== -1
          : false;

      // Only show the row if it matches both search criteria
      return !(descriptionMatches && boxIdMatches);
    })
    .hide();
});
