$(document).on("input", "#des-search, #b_id-search", function () {
  console.log("search event fired");

  var descriptionNeedle = RegExp(".*" + $("#des-search").val() + ".*", "i");
  var boxIdNeedle = RegExp(".*" + $("#b_id-search").val() + ".*", "i");

  $("#localcontents")
    .children()
    .show()
    .filter(function () {
      var descriptionHaystack = $(this).data("name");
      var boxIdHaystack = $(this).data("box-id");

      var descriptionMatches =
        descriptionHaystack !== undefined
          ? descriptionHaystack.search(descriptionNeedle) !== -1
          : false;
      var boxIdMatches =
        boxIdHaystack !== undefined
          ? boxIdHaystack.search(boxIdNeedle) !== -1
          : false;

      // Only show the row if it matches both search criteria
      return !(descriptionMatches && boxIdMatches);
    })
    .hide();
});

$(document).on("click", ".assign-media", function () {
  var mediaCheckboxes = document.querySelectorAll(".media-checkbox");
  var localContentCheckboxes = document.querySelectorAll(
    ".local-content-checkbox"
  );
  var selectedMediaNames = document.getElementById("selected-media-names");
  var selectedBoxIds = document.getElementById("selected-box-ids");

  // Get selected media names
  var selectedMedia = Array.from(mediaCheckboxes)
    .filter((checkbox) => checkbox.checked)
    .map((checkbox) =>
      $(checkbox).closest(".single-media").find("img").attr("alt")
    )
    .join(", ");

  // Get selected local content box_ids
  var selectedBoxes = Array.from(localContentCheckboxes)
    .filter((checkbox) => checkbox.checked)
    .map((checkbox) => $(checkbox).closest("tr").data("box-id"))
    .join(", ");

  // Update the modal with the selected names
  selectedMediaNames.textContent = selectedMedia || "None";
  selectedBoxIds.textContent = selectedBoxes || "None";
});

$(document).on("click", ".modal-save", function () {
  const selectedMediaIds = $(".media-checkbox:checked")
    .map(function () {
      return $(this).val();
    })
    .get()
    .join(",");

  const selectedLocalContentIds = $(".local-content-checkbox:checked")
    .map(function () {
      return $(this).val();
    })
    .get()
    .join(",");

  const mediaIdsField = $("#selected-media-ids");
  const localContentIdsField = $("#selected-local-content-ids");

  if (mediaIdsField.length && localContentIdsField.length) {
    mediaIdsField.val(selectedMediaIds);
    localContentIdsField.val(selectedLocalContentIds);
  } else {
    console.error(
      "Hidden fields for media_ids or local_content_ids not found."
    );
  }
});

$(document).on("click", "#player-spots", function (e) {
  e.preventDefault();
  // get the data url and submit the form to redirect to the url
  var url = $(this).data("url");

  var form = $("#edit_scenario_1");
  var formData = form.serialize(); // This turns form data into a query string

  // Redirect with form data as query parameters
  window.location.href = url + "?" + formData;
});
